import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAeysS5oBL0TZf-AUv2vr5S_YMhRPWWSiM",
  authDomain: "buglog-3792.firebaseapp.com",
  projectId: "buglog-3792",
  storageBucket: "buglog-3792.appspot.com",
  messagingSenderId: "591574769888",
  appId: "1:591574769888:web:51405669db700b58e47085",
  measurementId: "G-8Z395SDZGC"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

export { app, analytics, storage };