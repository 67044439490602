import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getBugsByProject,
  addBug,
  updateBugStatus,
  deleteBug,
  addVersion,
  getProjectUsers,
  inviteUserToProject,
  leaveProject,
  deleteProject,
} from '../../services/BugService';
import './Bugs.css';
import { FiUserPlus, FiPlusCircle, FiTrash, FiMoreHorizontal, FiShare, FiLogIn } from 'react-icons/fi';
import Header from '../../components/Header/Header';
import ProjectCardLoader from '../../components/ProjectCardLoader';
import { uploadFile } from "../../services/firebaseStorageService";
import Cookies from 'js-cookie';

const colorPalette = [
  '#007AFF',
  '#FF3B30',
  '#34C759',
  '#FFCC00',
  '#AF52DE',
  '#5AC8FA',
  '#5856D6',
  '#FF9500',
  '#FF2D55',
  '#AC8E68',
];

const getProjectColor = (projectName) => {
  const hash = projectName.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colorPalette[hash % colorPalette.length];
};

const Bugs = () => {
  const { projectId, isOwner, projectName } = useParams();
  const [groupedBugs, setGroupedBugs] = useState({});
  const [loading, setLoading] = useState(true);
  const [newBugTitle, setNewBugTitle] = useState('');
  const [newBugDescription, setNewBugDescription] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileURL, setFileURL] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [isBugModalOpen, setIsBugModalOpen] = useState(false);
  const [isVersionModalOpen, setIsVersionModalOpen] = useState(false);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [newVersionName, setNewVersionName] = useState('');
  const [newUserEmail, setNewUserEmail] = useState('');
  const [projectUsers, setProjectUsers] = useState([]);
  const [lastAddedVersion, setLastAddedVersion] = useState('');
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] = useState(false);
  const [confirmProjectName, setConfirmProjectName] = useState('');
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [nowVersion, setNowVersion] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBugsAndUsers = async () => {
      try {
        const bugList = await getBugsByProject(projectId);

        const grouped = bugList.reduce((acc, bug) => {
          const version = bug.version_name;
          if (!acc[version]) {
            acc[version] = [];
          }
          acc[version].push(bug);
          return acc;
        }, {});
        setGroupedBugs(grouped);

        const users = await getProjectUsers(projectId);
        setProjectUsers(users);
      } catch (error) {
        console.error('Veriler alınamadı:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBugsAndUsers();
  }, [projectId]);

  const handleAddBug = async () => {
    const token = Cookies.get("token");
    const bugData = {
      project_id: projectId,
      title: newBugTitle,
      description: newBugDescription,
      token: token,
      version_name: lastAddedVersion || Object.keys(groupedBugs)[0] || "v1.0",
      file_url: fileURL,
      source: "web",
    };

    try {
      const response = await addBug(bugData);

      const bugId = response.bug_id;
      const version = response.version_name || lastAddedVersion || Object.keys(groupedBugs)[0] || "v1.0";

      setGroupedBugs((prevGroupedBugs) => {
        const updatedGroupedBugs = { ...prevGroupedBugs };

        if (!updatedGroupedBugs[version]) {
          updatedGroupedBugs[version] = [];
        }

        updatedGroupedBugs[version] = [
          { ...response, id: bugId, title: newBugTitle, description: newBugDescription, email: "Siz" },
          ...updatedGroupedBugs[version]
        ];

        const orderedGroupedBugs = { [version]: updatedGroupedBugs[version], ...updatedGroupedBugs };

        return orderedGroupedBugs;
      });

      setNewBugTitle("");
      setNewBugDescription("");
      setFileURL("");
      setIsBugModalOpen(false);
    } catch (error) {
      console.error('Bug eklenemedi:', error);
    }
  };

  const handleNewVersion = async () => {
    try {
      await addVersion(projectId, newVersionName);
      setLastAddedVersion(newVersionName);
      setNewVersionName('');
      setIsVersionModalOpen(false);
    } catch (error) {
      console.error("Yeni sürüm eklenemedi:", error);
    }
  };

  const handleAddUser = async () => {
    try {
      await inviteUserToProject(projectId, newUserEmail);
      setNewUserEmail('');
      setIsUserModalOpen(false);
    } catch (error) {
      console.error("Kullanıcı projeye eklenemedi:", error);
    }
  };

  const handleLeaveProject = async () => {
    const token = Cookies.get("token");
    try {
      await leaveProject(projectId, "1", token);
      navigate("/");
      setIsInfoModalOpen(false);
    } catch (error) {
      console.error("Kullanıcı projeden çıkartılamadı:", error);
    }
  };

  const handleDeleteProject = async () => {
    const token = Cookies.get("token");
    try {
      await deleteProject(projectId, "1", token);
      setIsInfoModalOpen(false);
    } catch (error) {
      console.error("Proje silinemedi:", error);
    }
  };

  const handleToggleCompletion = async (bugId, isCompleted) => {
    console.log("Checkbox clicked:", bugId, "Completed status:", isCompleted);
    try {
      const response = await updateBugStatus(bugId, !isCompleted, 16);
      if (response && response.status === 'success') {
        setGroupedBugs((prevGroupedBugs) => {
          const updatedGroupedBugs = { ...prevGroupedBugs };
          Object.keys(updatedGroupedBugs).forEach((version) => {
            updatedGroupedBugs[version] = updatedGroupedBugs[version].map((bug) =>
              bug.id === bugId ? { ...bug, is_completed: !isCompleted } : bug
            );
          });
          return updatedGroupedBugs;
        });
      } else {
        console.error("Bug güncellenemedi:", response.message);
      }
    } catch (error) {
      console.error('API isteği başarısız oldu:', error);
    }
  };

  const handleDeleteBug = async (bugId) => {
    try {
      await deleteBug(bugId, 1);
      setGroupedBugs((prevGroupedBugs) => {
        const updatedGroupedBugs = { ...prevGroupedBugs };
        Object.keys(updatedGroupedBugs).forEach((version) => {
          updatedGroupedBugs[version] = updatedGroupedBugs[version].filter((bug) => bug.id !== bugId);
        });
        return updatedGroupedBugs;
      });
    } catch (error) {
      console.error('Bug silinemedi:', error);
    }
  };

  const renderInitials = (name) => {
    return name
      .split(" ")
      .slice(0, 2)
      .map(word => word[0])
      .join("")
      .toUpperCase();
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    setUploadStatus("Yükleniyor...");
    try {
      const url = await uploadFile(selectedFile);
      setFileURL(url);
      setUploadStatus("Yükleme başarılı!");
    } catch (error) {
      setUploadStatus("Yükleme başarısız!");
      console.error("Dosya yükleme hatası:", error);
    }
  };

  const handleAddBugWithFile = async () => {
    await handleAddBug(newBugTitle, newBugDescription, fileURL);
    setNewBugTitle("");
    setNewBugDescription("");
    setSelectedFile(null);
    setFileURL("");
    setUploadStatus("");
    setIsBugModalOpen(false);
  };

  const generateHashCode = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
    }
    return Math.abs(hash);
  };

  const buildFileContent = (projectName, versionName, versionBugs) => {
    let content = `[${projectName} ${versionName}]: * C999${generateHashCode(versionName)}\n`;
    content += "----------------------------------\n\n";

    versionBugs.forEach((bug) => {
      const bugStatusSymbol = bug.is_completed ? "✓" : "□";
      content += `${bugStatusSymbol} ${bug.title}\n`;
      content += `    ${bug.description}\n`;
    });

    return content;
  };

  const openShareModal = (projectName, versionName, versionBugs) => {
    setNowVersion(versionName);
    const content = buildFileContent(projectName, versionName, versionBugs);
    setPreviewContent(content);
    setIsShareModalOpen(true);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: `${projectName} - Versiyon Önizleme`,
        text: previewContent,
      }).catch(console.error);
    } else {
      console.log("Tarayıcınız paylaşımı desteklemiyor.");
    }
  };

  const handleDownload = () => {
    const blob = new Blob([previewContent], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${projectName}_Buglog_${nowVersion}.txt`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const token = Cookies.get("token");
  const headerButtons = [
    token === "11771177" ? {
      icon: <FiLogIn />,
      label: "Giriş Yap",
      onClick: () => navigate("/login"),
    } :
      {
        icon: <FiUserPlus />,
        label: "Kullanıcılar",
        onClick: () => setIsUserModalOpen(true),
      },
    {
      icon: <img src="/branch.svg" alt="Yeni Sürüm" height={15} />,
      label: "Yeni Sürüm",
      onClick: () => setIsVersionModalOpen(true),
    },
    {
      icon: <FiMoreHorizontal />,
      onClick: () => setIsInfoModalOpen(true),
    }
  ]

  return (
    <div>
      <Header buttons={headerButtons} />
      <div className="bugs-container">
        <header className="bugs-header">
          <div className="bugs-header-left">
            <div style={{ backgroundColor: `${getProjectColor(projectName)}20`, color: getProjectColor(projectName), border: `0.5px solid ${getProjectColor(projectName)}` }}
            >{renderInitials(projectName)}</div>
            <h1>{projectName}</h1>
          </div>
          <button onClick={() => setIsBugModalOpen(true)} className="add-bug-button">
            <span>Yeni İş Ekle</span>
            <FiPlusCircle color="#489BF4" size={20} />
          </button>
        </header>

        <div className="bug-list">
          <div className="search-bar">
            <input
              className="search-input"
              type="text"
              placeholder="İş ara..."
              onChange={(e) => {
                const searchTerm = e.target.value.toLowerCase();
                if (searchTerm === "") {
                  const fetchBugsAndUsers = async () => {
                    try {
                      const bugList = await getBugsByProject(projectId);

                      const grouped = bugList.reduce((acc, bug) => {
                        const version = bug.version_name;
                        if (!acc[version]) {
                          acc[version] = [];
                        }
                        acc[version].push(bug);
                        return acc;
                      }, {});
                      setGroupedBugs(grouped);

                      const users = await getProjectUsers(projectId);
                      setProjectUsers(users);
                    } catch (error) {
                      console.error('Veriler alınamadı:', error);
                    } finally {
                      setLoading(false);
                    }
                  };

                  fetchBugsAndUsers();
                } else {
                  setGroupedBugs((prevGroupedBugs) => {
                    const filteredGroupedBugs = {};
                    Object.keys(prevGroupedBugs).forEach((version) => {
                      const filteredBugs = prevGroupedBugs[version].filter((bug) =>
                        bug.title.toLowerCase().includes(searchTerm) ||
                        bug.description.toLowerCase().includes(searchTerm)
                      );
                      if (filteredBugs.length > 0) {
                        filteredGroupedBugs[version] = filteredBugs;
                      }
                    });
                    return filteredGroupedBugs;
                  });
                }
              }}
            />
          </div>
          {loading ? (
            Array.from({ length: 10 }).map((_, index) => (
              <ProjectCardLoader key={index} />
            ))
          ) : (Object.keys(groupedBugs).map((version) => (
            <div key={version} className="version-group">
              <div className="versionTitle">
                <div></div>
                <h2>{version}</h2>
                <button onClick={() => openShareModal(projectName, version, groupedBugs[version])}><FiShare size={17} color="#489BF4" /></button>
                <div></div>
              </div>
              {groupedBugs[version].map((bug) => (
                <div className="bug-card" key={bug.id}>
                  <div
                    className="custom-checkbox"
                    onClick={() => handleToggleCompletion(bug.id, bug.is_completed)}
                  >
                    <input
                      type="checkbox"
                      checked={bug.is_completed}
                      readOnly
                    />
                    <span className="checkmark"></span>
                  </div>
                  <div className="bug-info">
                    <div className="bug-info-left">
                      <h3 className="bug-title">{bug.title}</h3>
                      {bug.description !== "" ? <p className="bug-details">{bug.description}</p> : <></>}
                    </div>
                    <div className="bug-info-right">
                      <div>
                        <button onClick={() => navigate(`/bug_detail/${bug.id}`)}>
                          <span>Detay</span>
                        </button>
                        <button onClick={() => handleDeleteBug(bug.id)} className="delete-button">
                          <FiTrash color="#B9B9B9" size={20} />
                        </button>
                      </div>
                      <div className="bug-info-right-mail">
                        {bug.email}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )))}
        </div>

        {isBugModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <h2>Yeni İş Ekle</h2>

              <input
                id="bug-title"
                type="text"
                placeholder="İş Başlığı"
                value={newBugTitle}
                onChange={(e) => setNewBugTitle(e.target.value)}
                className="modal-input"
              />

              <textarea
                id="bug-description"
                placeholder="İş Açıklaması"
                value={newBugDescription}
                onChange={(e) => setNewBugDescription(e.target.value)}
                className="modal-input"
                rows={5}
              ></textarea>

              <div className="file-upload-section">
                <input
                  type="file"
                  id="file-upload"
                  onChange={handleFileChange}
                  className="file-input"
                />
                <button onClick={handleFileUpload} className="file-upload-button">
                  {uploadStatus === "Yükleniyor..." ? (
                    "Yükleniyor..."
                  ) : (
                    "Dosya Yükle"
                  )}
                </button>
              </div>

              <div className="upload-status">
                {uploadStatus && (
                  <p className={`status-message ${uploadStatus === "Yükleme başarılı!" ? "success" : "error"}`}>
                    {uploadStatus}
                  </p>
                )}
                {fileURL && (
                  <p className="uploaded-file-link">
                    <a href={fileURL} target="_blank" rel="noopener noreferrer">
                      Yüklenen Dosyayı Görüntüle
                    </a>
                  </p>
                )}
              </div>
              <br />

              <div className="modal-buttons">
                <button
                  onClick={handleAddBugWithFile}
                  className="modal-add-button"
                  disabled={uploadStatus === "Yükleniyor..."}
                >
                  Ekle
                </button>
                <button onClick={() => setIsBugModalOpen(false)} className="modal-close-button">
                  Kapat
                </button>
              </div>
            </div>
          </div>
        )}

        {isVersionModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <h2>Yeni Sürüm Ekle</h2>
              <p>Yeni bir sürüm ekleyerek mevcut sürümü kapatmak üzeresiniz. Sonraki işler yeni sürüm üzerinden kayıt edilecek, bu işlem geri alınamaz.</p>
              <input
                type="text"
                placeholder="Sürüm İsmi"
                value={newVersionName}
                onChange={(e) => setNewVersionName(e.target.value)}
                className="modal-input"
              />
              <button onClick={handleNewVersion} className="modal-add-button">Ekle</button>
              <button onClick={() => setIsVersionModalOpen(false)} className="modal-close-button">Kapat</button>
            </div>
          </div>
        )}

        {isUserModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <h2>Kullanıcı Ekle</h2>
              <input
                type="email"
                placeholder="Kullanıcı E-posta"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                className="modal-input"
              />
              <div className="current-users">
                <h3>Projeye Dahil Olan Kullanıcılar:</h3>
                <ul>
                  {projectUsers.map(user => (
                    <li key={user.id}>{user.name} - {user.email}</li>
                  ))}
                </ul>
              </div>
              <button onClick={handleAddUser} className="modal-add-button">Gönder</button>
              <button onClick={() => setIsUserModalOpen(false)} className="modal-close-button">İptal</button>
            </div>
          </div>
        )}

        {isInfoModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <h2>{projectName}</h2>
              <button
                onClick={() => {
                  if (navigator.share) {
                    navigator.share({
                      title: projectName,
                      text: `Sizi Buglog projeme davet ediyorum: https://www.buglog.app/invite/${projectId}/${projectName}`,
                      url: `https://www.buglog.app/invite/${projectId}/${projectName}`,
                    })
                      .then(() => console.log("Paylaşım başarılı!"))
                      .catch((error) => console.error("Paylaşım başarısız:", error));
                  } else {
                    alert("Tarayıcınız paylaşım özelliğini desteklemiyor.");
                  }
                }}
                className="modal-add-button"
              >
                Paylaş
              </button>

              <button
                onClick={() => {
                  navigator.clipboard.writeText(`https://www.buglog.app/invite/${projectId}/${projectName}`)
                    .then(() => alert("Bağlantı kopyalandı!"))
                    .catch((error) => console.error("Kopyalama hatası:", error));
                }}
                className="modal-add-button"
              >
                Bağlantıyı Kopyala
              </button>

              {token === "11771177" ? <></> : isOwner === "0" ? (
                <button onClick={handleLeaveProject} className="modal-close-button">
                  Projeden Ayrıl
                </button>
              ) : <></>}

              {isOwner === "1" ? (
                <button onClick={() => setIsDeleteConfirmModalOpen(true)} className="modal-close-button">
                  Projeyi Sil
                </button>
              ) : <></>}

              <button onClick={() => setIsInfoModalOpen(false)} className="modal-secondary-button">
                Kapat
              </button>
            </div>
          </div>
        )}

        {isDeleteConfirmModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <h2>Projeyi Sil</h2>
              <p>Lütfen projeyi silmek için "{projectName}" yazın:</p>
              <input
                className="modal-input"
                type="text"
                value={confirmProjectName}
                onChange={(e) => setConfirmProjectName(e.target.value)}
                placeholder="Proje adını girin"
              />
              <button
                onClick={() => {
                  if (confirmProjectName === projectName) {
                    setIsDeleteConfirmModalOpen(false);
                    handleDeleteProject();
                    navigate("/");
                  } else {
                    alert("Proje ismi doğrulanmadı. Lütfen doğru ismi girin.");
                  }
                }}
                className="modal-add-button"
              >
                Onayla ve Sil
              </button>
              <button onClick={() => setIsDeleteConfirmModalOpen(false)} className="modal-secondary-button">
                İptal
              </button>
            </div>
          </div>
        )}

        {isShareModalOpen && (
          <div className="modal">
            <div className="modal-content">
              <h2>Sürüm Önizlemesi</h2>
              <textarea
                value={previewContent}
                onChange={(e) => setPreviewContent(e.target.value)}
                className="modal-preview"
                rows={20}
              ></textarea>
              <div className="modal-buttons">
                <button onClick={handleShare} className="modal-secondary-button">Paylaş</button>
                <button onClick={handleDownload} className="modal-add-button">İndir</button>
                <button onClick={() => setIsShareModalOpen(false)} className="modal-close-button">Kapat</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Bugs;