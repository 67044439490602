import Cookies from 'js-cookie';
import { post } from './ApiService';

export const guest = async () => {

    Cookies.set("token", "11771177", {
      expires: 365 * 3,
      path: "/",
      sameSite: 'Strict',
      secure: window.location.protocol === 'https:'
    });

  return true;
};

export const login = async (email, password, action) => {
  const data = await post('/auth', { email, password, action });

  if (data.user) {
    Cookies.set('token', data.user.token, {
      expires: 365 * 3,
      path: '/',
      sameSite: 'Strict',
      secure: window.location.protocol === 'https:'
    });
  }

  return data;
};

export const register = async (name, email, password, action) => {
  const data = await post('/auth/register', { name, email, password, action });

  if (data.user) {
    Cookies.set('token', data.user.token, {
      expires: 365 * 3,
      path: '/',
      sameSite: 'Strict',
      secure: window.location.protocol === 'https:',
    });
  }

  return data;
};

export const logout = () => {
  Cookies.remove('token', { path: '/' });
};
