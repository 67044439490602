import React from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';

const Header = ({ buttons }) => {
    const navigate = useNavigate();

    return (
        <header className="header">
            <div className="header-container">
                <div className="header-logo">
                    <button onClick={() => navigate("/")} className="logo-button">
                        <img src="/logo.svg" alt="Buglog Logo" className="logo" />
                    </button>
                </div>
                <div className="header-buttons">
                    {buttons && buttons.map((button, index) => (
                        <button key={index} onClick={button.onClick} className="header-button">
                            {button.icon}
                            {button.label ? <span className="button-label">{button.label}</span> : <></>}
                        </button>
                    ))}
                </div>
            </div>
        </header>
    );
}

export default Header;
