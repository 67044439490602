import axios from 'axios';
import Cookies from 'js-cookie';

const API_BASE = process.env.REACT_APP_API_BASE;

const api = axios.create({
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
});

const addTokenToParams = (params = {}) => {
  const token = Cookies.get('token');
  return token ? { ...params, token } : params;
};

export const get = async (url, params = {}) => {
  try {
    const response = await api.get(url, { params: addTokenToParams(params) });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const post = async (url, data = {}) => {
  try {
    const response = await api.post(url, { ...data, token: Cookies.get('token') });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const put = async (url, data = {}) => {
  try {
    const response = await api.put(url, { ...data, token: Cookies.get('token') });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const del = async (url, data = {}) => {
  try {
    const response = await api.delete(url, {
      data: addTokenToParams(data)
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const handleError = (error) => {
  console.error("API error:", error);
  throw error;
};