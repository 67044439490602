import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserInfo, updateUserInfo } from '../../services/UserService';
import Cookies from 'js-cookie';

import './Profile.css';
import { FiLogOut } from 'react-icons/fi';
import Header from '../../components/Header/Header';

const Profile = () => {
  const [user, setUser] = useState({ name: '', email: '' });
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const userInfo = await getUserInfo();
        setUser(userInfo);
      } catch (err) {
        console.error("Kullanıcı bilgileri alınamadı:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateUserInfo({ ...user, password });
      setMessage("Profil güncellendi!");
    } catch (err) {
      console.error("Profil güncellenemedi:", err);
      setMessage("Bir hata oluştu, tekrar deneyin.");
    }
  };

  const handleLogout = () => {
    Cookies.remove("token");
    navigate("/");
    window.location.reload();
  };

  const headerButtons = [
    {
      icon: <FiLogOut />,
      label: "Çıkış Yap",
      onClick: handleLogout,
    }
  ];

  return (
    <div>
      <Header buttons={headerButtons} />
      {loading ? <div></div> : <div className="profile-container">
        {message && <p className="message">{message}</p>}
        <form onSubmit={handleUpdate}>
          <div className="form-group">
            <label htmlFor="name">Ad</label>
            <input
              type="text"
              id="name"
              name="name"
              value={user.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">E-posta</label>
            <input
              type="email"
              id="email"
              name="email"
              value={user.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Yeni Şifre</label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handlePasswordChange}
            />
          </div>
          <button type="submit" className="update-button">Güncelle</button>
        </form>
      </div>}
    </div>
  );
}

export default Profile;