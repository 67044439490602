import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBugDetails } from '../../services/BugService';
import './BugDetail.css';
import Header from '../../components/Header/Header';
// import { FiTrash } from 'react-icons/fi';

const BugDetail = () => {
  const { bugId } = useParams();
  const [bug, setBug] = useState(null);
  const [logs, setLogs] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBugDetails = async () => {
      try {
        const data = await getBugDetails(bugId);
        setBug(data.bug);
        setLogs(data.logs);
        setAttachments(data.attachments);
      } catch (error) {
        console.error('Bug detayları alınamadı:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBugDetails();
  }, [bugId]);


  const headerButtons = [
    /* {
      icon: <FiTrash />,
      label: "Sil",
      onClick: () => null,
    } */
  ];

  return (
    <div>
      <Header buttons={headerButtons} />
      <div className="bug-detail-container">
        {loading ? <div></div> : bug && (
          <>
            <header className="bug-header">
              <h1>{bug.title}</h1>
              {bug.status === "on" ? <span className={`status-badge ${bug.status === "on" ? "completed" : "incomplete"}`}>
                Tamamlandı
              </span> : <></>}
            </header>
            <section className="bug-info">
              <p>{bug.description}</p>
              <div className="bug-creator">
                <strong>Oluşturan:</strong> {bug.creator_email}
              </div>
            </section>
            <section className="bug-attachments">
              <h2>Ekler</h2>
              {attachments.length > 0 ? (
                attachments.map((attachment) => (
                  <div key={attachment.id} className="attachment-item">
                    <a href={attachment.file_path} target="_blank" rel="noopener noreferrer">Dosya</a>
                  </div>
                ))
              ) : (
                <p>Ek yok</p>
              )}
            </section>
            <section className="bug-logs">
              <h2>İşlem Geçmişi</h2>
              {logs.map((log, index) => (
                <div key={index} className="log-item">
                  <p>{log.action === "updated" ? "İş Güncellendi" : log.action === "added" ? "İş Eklendi" : "Bilinmeyen hareket"}</p>
                  <span className="log-timestamp">{log.created_at}</span>
                  <span className="log-user">{log.user_name}</span>
                </div>
              ))}
            </section>
          </>
        )}
      </div>
    </div>
  );
}

export default BugDetail;