import { get, post, put, del } from './ApiService';

export const getBugsByProject = async (projectId) => {
  const response = await get('/bugs', { project_id: projectId, source: "web" });
  return response.bugs;
};

export const addBug = async (bugData) => {
  const response = await post('/bugs', bugData);
  return response;
};

export const updateBugStatus = async (bugId, isCompleted, userId) => {
  const response = await put('/bugs', { id: bugId, is_completed: isCompleted, user_id: userId });
  return response;
};

export const deleteBug = async (bugId, userId) => {
  const response = await del('/bugs', { id: bugId, user_id: userId });
  return response;
};

export const inviteUserToProject = async (projectId, email) => {
  const response = await post('/invites', { project_id: projectId, email });
  return response;
};

export const addVersion = async (projectId, versionName) => {
  const response = await post('/versions', { uniq_id: projectId, version_name: versionName, source: "web" });
  return response;
};

export const getProjectUsers = async (projectId) => {
  const response = await get('/project_users', { project_id: projectId });
  return response.users;
};

export const getBugDetails = async (bugId) => {
  const response = await get(`/bug_detail?bug_id=${bugId}`);
  return response;
};

export const leaveProject = async (projectId, userId, token) => {
  const response = await del('/project_users', { project_id: projectId, user_id: userId, token: token });
  console.log(response);
  return response;
};

export const deleteProject = async (projectId, userId, token) => {
  const response = await del('/projects', { project_id: projectId, user_id: userId, token: token });
  return response;
};
