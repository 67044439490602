import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllProjects } from '../../services/ProjectService';
import ProjectCardLoader from '../../components/ProjectCardLoader';
import './Dashboard.css';
import Header from '../../components/Header/Header';
import { FiUser, FiPlusCircle } from 'react-icons/fi';

const colorPalette = [
  '#007AFF',
  '#FF3B30',
  '#34C759',
  '#FFCC00',
  '#AF52DE',
  '#5AC8FA',
  '#5856D6',
  '#FF9500',
  '#FF2D55',
  '#AC8E68',
];

const getProjectColor = (projectName) => {
  const hash = projectName.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
  return colorPalette[hash % colorPalette.length];
};

const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchProjects = async () => {
    try {
      const projectList = await getAllProjects();
      setProjects(Array.isArray(projectList) ? projectList : []);
    } catch (err) {
      console.error("Projeler alınamadı:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const renderInitials = (name) => {
    return name
      .split(" ")
      .slice(0, 2)
      .map(word => word[0])
      .join("")
      .toUpperCase();
  };

  const headerButtons = [
    {
      icon: <FiPlusCircle />,
      label: "Proje Oluştur",
      onClick: () => navigate("/add-project"),
    },
    {
      icon: <FiUser />,
      label: "Profil",
      onClick: () => navigate("/profile"),
    }
  ];

  return (
    <div>
      <Header buttons={headerButtons} />
      <div className="dashboard">
        <div className="project-list">
          <div className="search-bar">
            <input
              className="search-input"
              type="text"
              placeholder="Projelerde ara..."
              onChange={(e) => {
                const searchTerm = e.target.value.toLowerCase();
                if (searchTerm === "") {
                  fetchProjects();
                } else {
                  setProjects((prevProjects) =>
                    prevProjects.filter((project) =>
                      project.name.toLowerCase().includes(searchTerm)
                    )
                  );
                }
              }}
            />
          </div>
          {loading ? (
            Array.from({ length: 10 }).map((_, index) => (
              <ProjectCardLoader key={index} />
            ))
          ) : (
            projects.map((project) => (
              <div
                className="project-card"
                key={project.id}
                onClick={() => navigate(`/bugs/${project.uniq}/${project.is_owner}/${project.name}`)}
              >
                <div
                  className="project-initials"
                  style={{ backgroundColor: `${getProjectColor(project.name)}20`, color: getProjectColor(project.name), borderColor: getProjectColor(project.name) }}
                >
                  {renderInitials(project.name)}
                </div>
                <div className="project-info">
                  <h3 className="project-name">{project.name}</h3>
                  <p className="project-description">{project.description}</p>
                </div>
                <span className="arrow">›</span>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;