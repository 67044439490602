import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addProject } from '../../services/ProjectService';
import './AddProject.css';
import Header from '../../components/Header/Header';

const AddProject = () => {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const navigate = useNavigate();

  const handleAddProject = async () => {
    const projectData = {
      name: projectName,
      description: projectDescription,
      created_by: 1
    };

    try {
      await addProject(projectData);
      navigate('/');
    } catch (error) {
      console.error('Proje eklenemedi:', error);
    }
  };

  return (
    <div>
      <Header buttons={[]} />
      <div className="add-project-container">
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <label htmlFor="projectName">Proje Adı</label>
          <input
            type="text"
            id="projectName"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="projectDescription">Proje Açıklaması</label>
          <textarea
            id="projectDescription"
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <button onClick={handleAddProject} className="add-project-button">Proje Ekle</button>
      </form>
    </div>
    </div>
  );
};

export default AddProject;
