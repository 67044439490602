import { get, put } from './ApiService';

export const getUserInfo = async (token) => {
  const response = await get('/user/profile', { token });
  return response.user;
};

export const updateUserInfo = async (userData) => {
  const response = await put('/user/profile', userData);
  return response;
};