import React from 'react';
import ContentLoader from 'react-content-loader';

const ProjectCardLoader = (props) => (
  <ContentLoader
    speed={2}
    width={800}
    height={80}
    viewBox="0 0 800 80"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="20" y="15" rx="8" ry="8" width="50" height="50" />
    <rect x="80" y="20" rx="5" ry="5" width="200" height="15" />
    <rect x="80" y="45" rx="5" ry="5" width="150" height="10" />
    <rect x="750" y="35" rx="5" ry="5" width="10" height="15" />
  </ContentLoader>
);

export default ProjectCardLoader;
