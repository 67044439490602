import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { checkAuthentication, addUser } from '../../services/InviteService';
import './Invite.css';

const InvitePage = () => {
  const { uniqid, title } = useParams();
  const [message, setMessage] = useState("Hazırlık yapılıyor...");
  const navigate = useNavigate();

  const handleJoinProject = useCallback(async () => {
    try {
      const result = await addUser(uniqid);
      if (result.status === "success") {
        setMessage("Projeye başarıyla katıldınız, yönlendiriliyor...");
        setTimeout(() => {
          navigate(`/project/${result.projectId}`);
        }, 2000);
      } else {
        setMessage("Projeye başarıyla katıldınız, yönlendiriliyor...");
        setTimeout(() => {
          navigate(`/bugs/${uniqid}/0/${result.title}`);
        }, 2000);
      }
    } catch (error) {
      console.error("Projeye katılamadı:", error);
      setMessage("Bir hata oluştu, ana sayfaya yönlendiriliyor...");
      setTimeout(() => {
        navigate('/dashboard');
      }, 2000);
    }
  }, [navigate, uniqid]);

  useEffect(() => {
    const authenticateUser = async () => {
      setMessage("Kimlik doğrulama yapılıyor...");

      setTimeout(async () => {
        const isAuthenticated = await checkAuthentication();

        if (!isAuthenticated) {
          setMessage("Giriş yapmanız gerekmektedir, yönlendiriliyor...");
          setTimeout(() => {
            navigate(`/login?route=/invite/${uniqid}/${title}`, { state: { from: `/invite/${uniqid}` } });
          }, 2000);
        } else {
          setMessage("Projeye katılım sağlanıyor...");
          setTimeout(() => handleJoinProject(), 2000);
        }
      }, 2000);
    };

    authenticateUser();
  }, [uniqid, title, navigate, handleJoinProject]);

  return (
    <div className="invite-page-container">
      <div className="invite-logo">
        <img src="/logo_blue.svg" alt="Logo" className="logo" />
      </div>
      <div className="invite-message">
        <p>{message}</p>
      </div>
      <div className="loader"></div>
    </div>
  );
};

export default InvitePage;