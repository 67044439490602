import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { guest, login } from '../../services/AuthService';
import './Login.css';

const Login = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const route = searchParams.get("route");
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();


  const handleGuestSubmit = async () => {
    const parts = route.split("/");
    const uniqid = parts[2];
    const title = parts[3] === "undefined" ? "Proje" : parts[3];
    console.log(uniqid);
    console.log(title);
    await guest();
    navigate(`/bugs/${uniqid}/0/${title}`)
    window.location.reload();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    try {
      await login(email, password, "login");
      if (route) {
        navigate(route);
      } else {
        navigate("/dashboard");
      }
      window.location.reload();
    } catch (err) {
      setError("Giriş başarısız. E-posta adresi veya şifre hatalı.");
    }
  };

  return (
    <div className="login-container">
      <div className="logo">
        <img src="/logo_blue.svg" alt="Logo" />
      </div>
      <h2>Buglog</h2>
      {
        route ?
          <button onClick={() => handleGuestSubmit()} style={{marginBottom:"20px"}}>Misafir olarak devam et</button>
          :
          <></>
      }
      {route ? <div className="invite-alert">Giriş yaptıktan sonra ilgili davete yönlendirileceksiniz...</div> : <></>}
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="E-posta Adresi"
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Şifre"
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit">Giriş Yap</button>
      </form>
      <p className="register-prompt">
        Henüz hesabınız yok mu?{" "}
        <button onClick={() => navigate("/register")} className="register-link">
          Kayıt Ol
        </button>
      </p>
    </div>
  );
}

export default Login;
