import { get, post } from './ApiService';

export const getAllProjects = async () => {
  const response = await get('/projects');
  return response.projects || [];
};

export const addProject = async (projectData) => {
  const response = await post('/projects', projectData);
  return response;
};