import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../../services/AuthService';
import './Register.css';

const Register = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleRegister = async () => {
    try {
      await register(name, email, password, "register");
      setMessage('Kayıt başarılı! Giriş sayfasına yönlendiriliyorsunuz...');
      setTimeout(() => navigate('/'), 2000);
    } catch (error) {
      setMessage('Kayıt işlemi başarısız');
    }
  };

  return (
    <div className="register-container">
      <h1>Kayıt Ol</h1>
      {message && <p className="message">{message}</p>}
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="form-group">
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            placeholder="İsim Soyisim"
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="E-posta Adresi"
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Şifre"
          />
        </div>
        <button onClick={handleRegister} className="register-button">Kayıt Ol</button>
      </form>
    </div>
  );
};

export default Register;
