import { post } from './ApiService';
import Cookies from 'js-cookie';

export const checkAuthentication = async () => {
    try {
        const token = Cookies.get("token");
        if (token === "11771177")
            return false;
        if (token)
            return true;
        return false;
    } catch {
        return false;
    }
}

export const addUser = async (projectId) => {
  const token = Cookies.get("token");
  const response = await post('/project_users', { uniqid: projectId, token: token, source: "web" });
  return response;
};
